import { Link } from '@/components/Link';
import { useStableProps } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import {
  cancelSubscription,
  defaultStatuses,
  getPaymentsItems,
  GetPaymentsResponse,
  StandaloneSubscriptionDetails,
  StandaloneSubscriptionDetailsProps,
} from 'base/components/SubscriptionDetails';
import { StandaloneSubscriptionDetailsDigital } from 'base/components/SubscriptionDetails/Digital';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

export const FeminaStandaloneSubscriptionDetails: typeof StandaloneSubscriptionDetails = (props) => {
  const [paymentHistory, setPaymentHistory] = useState<GetPaymentsResponse | undefined>();
  const { reload } = useRouter();

  useEffectOnce(() => {
    async function fetchData() {
      const data = await getPaymentsItems();
      setPaymentHistory(data);
    }
    fetchData();
  });

  const stableProps: StandaloneSubscriptionDetailsProps = useStableProps({
    digitalSubscriptionAccordionProps: {
      title: (
        <>
          Hantera din <strong>digitala prenumeration</strong>
        </>
      ),
      content: useMemo(
        () => (
          <StandaloneSubscriptionDetailsDigital
            headline="Femina plus"
            description="Tillgång till alla digitala artiklar på femina.se"
            detailsList={[
              <>
                Påbörjad: <b>{paymentHistory?.startDate}</b>
              </>,
              <>
                Avslutas: <b>{paymentHistory?.endDate}</b>
              </>,
              <>
                Status: <b>{paymentHistory?.status ? defaultStatuses[paymentHistory.status] : 'Okänd'}</b>
              </>,
            ]}
            buttonLabels={{
              paymentHistory: 'Betalningshistorik',
              cancelSubscription: 'Avsluta prenumeration',
            }}
            contactInfo={
              <>
                Om du har några frågor, kontakta oss på{' '}
                <Link content="femina@kundtjanst.se" href="mailto:femina@kundtjanst.se" />
              </>
            }
            history={{
              headline: 'Din betalningshistorik',
              items: paymentHistory?.payments?.map(({ date, description, amount, currency }) => ({
                date,
                description,
                price: `${amount / 100} ${currency}`,
              })),
            }}
            buttonOptions={{
              cancelSubscription: {
                disabled: paymentHistory?.status === 'cancelled',
                onClick: async () => {
                  await cancelSubscription({
                    subscriptionId: paymentHistory?.subscriptionId || '',
                  });
                  reload();
                },
              },
            }}
          />
        ),
        [paymentHistory, reload],
      ),
    },
    printSubscriptionAccordionProps: {
      title: (
        <>
          Hantera din <strong>print-prenumeration</strong>
        </>
      ),
      content: useMemo(
        () => (
          <>
            Dina printprenumerationer hanteras på prenumerera.se,
            <br className="hidden md:block" />
            <Link
              href="https://prenumerera.se/kundservice/"
              content="klicka här för att komma dit"
              options={{ className: 'text-summer-red-700 underline' }}
            />
          </>
        ),
        [],
      ),
    },
  });
  return <StandaloneSubscriptionDetails {...mergeProps(stableProps, props)} />;
};
